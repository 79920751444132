import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useSearchParams } from 'react-router-dom';

import { Skeleton } from '@mui/material';
import {
  PlanResponseModel,
  Price
} from '~/app/application/features/auth/domain/models/plans/plan-response-model';
import PlanCardUpgrade from '~/app/application/features/components/plan-card/plan-card';
import PlanDetailsTable from '~/app/application/features/components/plan-details-table/plan-details-table';
import SettingPlan from '~/app/application/features/components/success-setting/success-setting';
import { useAuth } from '~/app/core/presentation/hooks/use-auth';
import { logEvent } from '~/app/application/shared/lib/tag';

import { UpgradePlanRoutes } from '../../../routes/routes';
import {
  useContactSalesMutation,
  useGetPlansMutation,
  useGetSubscriptionByCompanyIdMutation
} from '../../../store/hooks';
import { useRegisterUserDataStore } from '../../../store/slice/register-user-data-slice';
import CardCurrentPlan from './card-current-plan-header/upgrade-plan';
import { createPlans } from './plans-list';
import UpgradePlanHeader from './upgrade-plan-header/upgrade-plan-header';
import {
  ContainerUpgradePlan,
  PlansCardsContainer,
  SecondTitle,
  TableContainer,
  HeaderContainer,
  MainContainer
} from './upgrade-plan-styles';

const UpgradePlan = () => {
  const { t, i18n } = useTranslation('register-plan');
  const normalizedLanguage = i18n.language.replace('-', '_');
  const { registerState, setRegisterState } = useRegisterUserDataStore();
  const navigate = useNavigate();
  const [currency, setCurrency] = useState('R$');
  const [yearlyPrice, setYearlyPrice] = useState<Price>();
  const [monthlyPrice, setMonthlyPrice] = useState<Price>();
  const [displaySuccess, setDisplaySuccess] = useState(false);
  const [plans, setPlans] = useState<PlanResponseModel[]>([]);
  const [contactSales] = useContactSalesMutation();
  const [getSubscription] = useGetSubscriptionByCompanyIdMutation();
  const [getPlans] = useGetPlansMutation();
  const [isLoading, setIsLoading] = useState(true);
  const [currentPlan, setCurrentPlan] = useState<string | null>(null);
  const [searchParams] = useSearchParams();
  const base64EncodedString = searchParams.get('authUser');
  const [renewDate, setRenewDate] = useState(null);
  const { useLogin } = useAuth();

  const onDisplayPaymentPlanPro = () => {
    logEvent({
      category: 'select_plan_pro_upgrade/trigger',
      action: 'OnSubmit',
      label: 'select_plan_pro_upgrade'
    });

    return navigate(UpgradePlanRoutes.PLAN_UPGRADE_PRO);
  };

  useEffect(() => {
    setCurrency(plans[3]?.products[0]?.prices[0]?.symbol);
    const yearlyPrice = plans[3]?.products[0]?.prices.find(
      (price) => price.frequency === 'yearly'
    );
    setYearlyPrice(yearlyPrice);
    const monthlyPrice = plans[3]?.products[0]?.prices.find(
      (price) => price.frequency === 'monthly'
    );
    setMonthlyPrice(monthlyPrice);
  }, [plans]);

  const headerTexts = {
    free: {
      title: t('enjoy_basic_features'),
      description: t('current_workspace')
    },
    pro: {
      title: t('current_plan_pro'),
      description: t('current_plan_pro_description', {
        renewal_date: renewDate
      })
    }
  };

  useEffect(() => {
    getPlans({})
      .unwrap()
      .then((response) => {
        setPlans(response);
        setRegisterState({ plans: response });
      })
      .catch((error) => {
        console.error(error);
      });
  }, []);

  useEffect(() => {
    const decodedString = atob(base64EncodedString);

    const authUser = JSON.parse(decodedString);

    const { companies, user, vehiclesCount } = authUser;

    useLogin(user);

    // TODO: Remove this when the backend is fixed
    delete user.accessToken;
    delete user.refreshToken;

    setRegisterState({
      companies,
      vehiclesCount,
      user
    });
  }, [base64EncodedString]);

  useEffect(() => {
    const fetchData = async () => {
      if (!(plans && plans.length > 0)) return;
      if (registerState.companies.length <= 0) return;
      getSubscription({
        companyId: registerState.companies[0]._id
      })
        .unwrap()
        .then((response) => {
          plans.forEach((itemPlan) => {
            if (!(itemPlan._id === response.planId)) return;
            setCurrentPlan(itemPlan.type);
            setIsLoading(false);
            if (!response.endAt || !i18n.language) return;
            const dataFormate = new Date(response.endAt).toLocaleDateString(
              i18n.language,
              { day: '2-digit', month: 'long', year: 'numeric' }
            );
            setRenewDate(dataFormate);
            setRegisterState({
              companies: [
                { ...registerState.companies[0], subscriptions: [response._id] }
              ]
            });
          });
        });
    };
    fetchData();
  }, [plans]);

  const contactSalesRequest = (values) => {
    contactSales(values)
      .unwrap()
      .then(() => {
        setDisplaySuccess(true);
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const onDisplayContactSales = () => {
    logEvent({
      category: 'select_plan_enterprise_upgrade/trigger',
      action: 'OnSubmit',
      label: 'select_plan_enterprise_upgrade'
    });

    return contactSalesRequest({
      name: registerState?.user?.firstName
        ? `${registerState?.user?.firstName} ${registerState?.user?.lastName}`
        : undefined,
      email: registerState?.user?.email,
      phone: registerState?.companies[0]?.phones?.[0]
        ? `${registerState?.companies[0]?.phones?.[0]?.regionCode}${registerState?.companies[0]?.phones?.[0]?.phone}`
        : undefined,
      companyName: registerState?.companies[0]?.name
    });
  };

  const plansForTable = createPlans({
    freePlan: {
      currentPlan: currentPlan === 'free',
      disableColumn: currentPlan === 'pro' || currentPlan === 'customized',
      onclick: () => false
    },
    proPlan: {
      currentPlan: currentPlan === 'pro',
      onclick: onDisplayPaymentPlanPro,
      disableColumn: false
    },
    enterprisePlan: {
      currentPlan: currentPlan === 'customized',
      onclick: onDisplayContactSales,
      disableColumn: false
    }
  });

  const displayPlans = !displaySuccess;

  return (
    <>
      {displayPlans && (
        <ContainerUpgradePlan>
          <MainContainer>
            <HeaderContainer>
              {currentPlan && (
                <CardCurrentPlan
                  title={headerTexts[currentPlan].title}
                  description={headerTexts[currentPlan].description}
                />
              )}

              <UpgradePlanHeader
                title={t('simplify_header')}
                description={t('simplify_text')}
              />
            </HeaderContainer>

            <PlansCardsContainer>
              {isLoading ? (
                <>
                  <Skeleton variant='rectangular' width={340} height={359} />
                  <Skeleton variant='rectangular' width={340} height={359} />
                  <Skeleton variant='rectangular' width={340} height={359} />
                </>
              ) : (
                <>
                  {!(currentPlan === 'pro' || currentPlan === 'customized') && (
                    <PlanCardUpgrade
                      color='#63AB36'
                      title={t('starter')}
                      currency={t('currency')}
                      price={0}
                      description={t('starter_description')}
                      buttonText={t('try_free')}
                      buttonAction={null}
                      isCurrentPlan={currentPlan === 'free'}
                      isBorderRadiusButton
                    />
                  )}
                  <PlanCardUpgrade
                    badgeText={t('best_seller')}
                    color='#457826'
                    title={plans[0]?.name?.[normalizedLanguage]}
                    currency={currency}
                    price={yearlyPrice?.amount}
                    description={plans[0]?.description?.[normalizedLanguage]}
                    buttonText={t('start_with_pro')}
                    isCurrentPlan={currentPlan === 'pro'}
                    observation={`${t(
                      'billed_annually'
                    )}. ${monthlyPrice?.symbol} ${monthlyPrice?.amount
                      .toFixed(2)
                      .replace('.', ',')} ${t('billed_monthly')}.`}
                    buttonAction={onDisplayPaymentPlanPro}
                    isBorderRadiusButton
                  />
                  <PlanCardUpgrade
                    color='#284516'
                    title={t('enterprise')}
                    currency={t('custom_price')}
                    isCurrentPlan={currentPlan === 'customized'}
                    description={t('enterprise_description')}
                    buttonText={t('contact_sales')}
                    buttonAction={onDisplayContactSales}
                    isBorderRadiusButton
                  />
                </>
              )}
            </PlansCardsContainer>
            <TableContainer>
              {!isLoading && <SecondTitle>{t('compare_benefits')}</SecondTitle>}
              <PlanDetailsTable
                plansData={plansForTable}
                isLoading={isLoading}
              />
            </TableContainer>
          </MainContainer>
        </ContainerUpgradePlan>
      )}
      {displaySuccess && (
        <SettingPlan
          title={t('success_contact')}
          description={t('success_contact_message')}
          status='success'
          disableLogo={true}
        />
      )}
    </>
  );
};
export default UpgradePlan;
