import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Divider } from '@mui/material';
import { Price } from '~/app/application/features/auth/domain/models/plans/plan-response-model';
import { useRegisterUserDataStore } from '~/app/application/features/auth/store/slice/register-user-data-slice';

import {
  PaymentResumeContainer,
  PaymentResumeHeader,
  PaymentResumePlanDescription,
  PaymentResumePlanExtra,
  PaymentResumeTotalBox
} from './payment-resume-box-styles';

type PaymentResumeBoxProps = {
  price: Price;
  showCountVehicles?: boolean;
  handleSavePrice?: (priceValue: string) => void;
  backgroundPrimary?: boolean;
};

const PaymentResumeBox = ({
  price,
  showCountVehicles,
  handleSavePrice,
  backgroundPrimary
}: PaymentResumeBoxProps) => {
  const { registerState } = useRegisterUserDataStore();
  const { t } = useTranslation('payment-form');
  const [selectedPrice, setSelectedPrice] = useState<Price>();

  const [total, setTotal] = useState('0,00');
  const isYearly = selectedPrice?.frequency === 'yearly';

  useEffect(() => {
    const selectedAmount = Number(selectedPrice?.amount ?? 0);
    const total = selectedAmount;
    const formattedTotal = total.toLocaleString('pt-BR', { minimumFractionDigits: 2 });
  
    setTotal(formattedTotal);
    handleSavePrice?.(formattedTotal);
  }, [selectedPrice, registerState, total]);
  

  useEffect(() => {
    setSelectedPrice(price);
  }, [price]);

  return (
    <PaymentResumeContainer primary={backgroundPrimary}>
      <PaymentResumeHeader>
        {t('payment-resume-box.summary')}
      </PaymentResumeHeader>
      {/* {showCountVehicles && (
        <PaymentResumePlanDescription>
          <div>Veiculos</div>
          <div>{registerState?.vehiclesCount}</div>
        </PaymentResumePlanDescription>
      )} */}
      <PaymentResumePlanDescription>
        <div>{t('payment-resume-box.proPlan')}</div>
        <div>
          R$ {total} /{' '}
          {isYearly
            ? t('payment-resume-box.perYear')
            : t('payment-resume-box.perMonth')}
        </div>
      </PaymentResumePlanDescription>

      <PaymentResumePlanExtra>
        {isYearly
          ? t('payment-resume-box.annualSubscriptionWithDiscount')
          : t('payment-resume-box.monthlySubscription')}
      </PaymentResumePlanExtra>

      <Divider />
      <PaymentResumeTotalBox>
        <div>{t('payment-resume-box.total')}</div>
        <div>R$ {total}</div>
      </PaymentResumeTotalBox>
    </PaymentResumeContainer>
  );
};
export default PaymentResumeBox;
